<template>
  <div v-show="allowed">
    <div class="form--group form--group-switch mb-0">
      <div class="left--col">
        <span>{{ $t('addListing.operational.title') }}</span>
      </div>
      <div class="right--col">
        <button
          type="button"
          class="btn px-4"
          :class="{ selected: showOperationalHour }"
          @click="showOperationalHour = true"
        >
          {{ $t('general.yes') }}
        </button>
        <button
          type="button"
          class="btn px-4"
          :class="{ selected: !showOperationalHour }"
          @click="showOperationalHour = false"
        >
          <span style="position: relative">
            {{ $t('general.no') }}
          </span>
        </button>
      </div>
    </div>
    <listing-form-transition>
      <div v-show="showOperationalHour">
        <div class="form--group" :class="{ 'has-error': validation.hasError('covidStatus') }">
          <div class="left--col"></div>
          <div class="right--col">
            <div class="alert alert-warning mb-0" role="alert">
              {{ $t('addListing.operational.message') }}
            </div>
            <div class="form--group">
              <div class="row my-0">
                <div class="row align-center my-4 mx-0">
                  <div class="col-4 py-0">
                    <div class="operational--title">{{ $t('addListing.operational.day') }}</div>
                  </div>
                  <div class="col-4 py-0">
                    <div class="operational--title">
                      {{ $t('addListing.operational.open') }}
                    </div>
                  </div>
                  <div class="col-4 py-0">
                    <div class="operational--title">
                      {{ $t('addListing.operational.close') }}
                    </div>
                  </div>
                </div>
                <div class="col-12 py-0 mb-4">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      v-model="differentTime"
                      class="custom-control-input"
                      name="differentDayCheck"
                      id="differentDay"
                    />
                    <label class="custom-control-label" for="differentDay">{{
                      $t('addListing.operational.diff')
                    }}</label>
                  </div>
                </div>
                <operational-hour-field
                  v-for="(listingOperational, index) in listingOperationals"
                  :key="index"
                  :idx="index"
                  :day="listingOperational.day"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </listing-form-transition>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import { mapState } from 'vuex';
import ListingFormTransition from '@/components/listing-form/listing-form-transition';

const OperationalHourField = () =>
  import('@/components/listing-form/sect2/operational-hour/operational-hour-field.vue');
export default {
  name: 'operational-hour-section',
  components: { OperationalHourField, ListingFormTransition },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      listingOperationals: (state) => state.v2.listingForm.sect2.availability.listingOperationals,
      allowed: (state) => state.v2.listingForm.sect2.availability.allowOperationalHour,
    }),

    differentTime: {
      get() {
        return this.$store.state.v2.listingForm.sect2.availability.differentTime;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect2/availability/TOGGLE_DIFFERENT_TIME', value);
      },
    },
    showOperationalHour: {
      get() {
        return this.$store.state.v2.listingForm.sect2.availability.showOperationalHour;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect2/availability/SET_SHOW_OPERATIONAL_HOUR', value);
      },
    },
  },
  validators: {
    covidStatus: {
      validator(value) {
        return (
          Validator.value(value).required(
            this.$i18n.t('errors.addListing.protocolStatus.required'),
          ) || !this.useProtocol
        );
      },
    },
    covidProtocol: {
      validator(value) {
        return (
          Validator.value(value).required(this.$i18n.t('errors.addListing.protocol.required')) ||
          !this.useProtocol
        );
      },
    },
  },
};
</script>

<style scoped></style>
